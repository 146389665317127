import React, { useEffect } from "react";
import "./App.css";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set , onValue} from "firebase/database";
import toast,{ Toaster } from "react-hot-toast";

import jsonfile from "../package.json";


const Form = () => {
    const [maincard, setMaincard] = React.useState("");
    const [blink1, setBlink1] = React.useState("");
    const [blink2, setBlink2] = React.useState("");
    const [blink3, setBlink3] = React.useState("");
    const [disabled, setDisabled] = React.useState(true);
    const [date, setDate] = React.useState(new Date());
    const handleCheckbox = () => {
        setDisabled(!disabled);
    };
    const version = jsonfile.version;
    const firebaseConfig = {
        apiKey: "AIzaSyAgqe_W0Vum_GD_eq21DHsQI-yvhyM2MxE",
        authDomain: "njtran-a99eb.firebaseapp.com",
        databaseURL: "https://njtran-a99eb-default-rtdb.firebaseio.com",
        projectId: "njtran-a99eb",
        storageBucket: "njtran-a99eb.appspot.com",
        messagingSenderId: "491001449073",
        appId: "1:491001449073:web:e10b5ff3b467eb532aa9d0",
        measurementId: "G-7B0HY33Q9V"
      };
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);

    const handleSubmit = async (e) => {
        var date = new Date();
        e.preventDefault()
        try { 
            checkDigit("મેઈન કાર્ડ",maincard);
            checkDigit("બ્લિંક 1", blink1);
            checkDigit("બ્લિંક 2", blink2);
            checkDigit("બ્લિંક 3",blink3);
        } catch (e) {
            if (e instanceof ValidationError) {
                toast.error(e.message, { icon: '🤬' })
                return;
            }   
        }
        set(ref(database, '/'), {
            maincard: maincard,
            blink1: blink1,
            blink2: blink2,
            blink3: blink3,
            date: date.toLocaleDateString() + " " + date.toLocaleTimeString()
        })
            .then(() => {
                toast("અપડેટ થઇ ગયું , વાપરો ", { icon: '👏' })
                setMaincard("");
                setBlink1("");
                setBlink2("");
                setBlink3("");
                setDisabled(true);
                console.log('Data updated successfully');
            })
            .catch((error) => {
                console.error('Error updating data: ', error);
            });
    };
    useEffect(() => {
        const starCountRef = ref(database, '/' + 'date');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            setDate(new Date(data));
          });
    }, []);
    return (
        <>
        <div><Toaster position="top-center" reverseOrder={false}/></div>
            <div background-color="grey" text-align="centre">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h2 className="text-center text-dark mt-5">update color {version}</h2>
                            <h4 className="text-center text-dark mt-5">Last Updated on {date.toLocaleDateString() + " " + date.toLocaleTimeString()}</h4> 
                            <div className="card my-5">
                                <form className="card-body cardbody-color p-lg-5" id="update-color" method="post" onSubmit={handleSubmit} >
                                    <div className="mb-3">
                                        <input type="text" value={maincard} onChange={e => setMaincard(e.target.value)} className="form-control" name="maincard" id="maincard" placeholder="maincard"
                                            minLength="6" maxLength="7" required />
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" value={blink1} onChange={e => setBlink1(e.target.value)} className="form-control" name="blink1" id="blink1" placeholder="blink1"
                                            minLength="6" maxLength="7" required />
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" value={blink2} onChange={e => setBlink2(e.target.value)} className="form-control" name="blink2" id="blink2" placeholder="blink2"
                                            minLength="6" maxLength="7" required />
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" value={blink3} onChange={e => setBlink3(e.target.value)} className="form-control" name="blink3" id="blink3" placeholder="blink3"
                                            minLength="6" maxLength="7" required />
                                    </div>
                                    <div className="mb-3">
                                        <input className="form-control" type="checkbox" name="new" id="new" onChange={handleCheckbox} required />
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" id="colorchange" name="colorchange" disabled={disabled}
                                            className="btn btn-color px-5 mb-5 w-100">update</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const checkDigit = (input,color) => {
    if (!color.includes("#") ) {
        throw new ValidationError(`${input} નંબર માન્ય નથી ટોપા # ઘટે છે`);
    }

    if (color.includes("#") && color.length === 6) {
        throw new ValidationError(`${input} નંબર માન્ય નથી ટોપા 6 અંક હોવા જોઈએ # પછી`);
    }
};

class ValidationError extends Error {
    constructor(message) {
      super(message);
      this.name = "ValidationError";
    }
}
export default Form;